import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'components/Seo'
import PageTemplate from './template'

const ContentType = (props) => {
  const { data, pageContext, layoutProps } = props
  const { locale } = pageContext
  const { page, investorPostStatic } = data
  const content = page.frontmatter[locale]
  const investorStatic = investorPostStatic.frontmatter[locale]

  return (
    <>
      <Seo content={content} locale={locale} />
      <PageTemplate
        pageId={page.id}
        content={content}
        investorStatic={investorStatic}
        locale={locale}
        layoutProps={layoutProps}
      />
    </>
  )
}

export default ContentType

export const pageQuery = graphql`
  query ($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        en {
          date
          location
          title
          body
          file {
            publicURL
          }
          seo {
            title
            description
          }
        }
        zh {
          date
          location
          title
          body
          file {
            publicURL
          }
          seo {
            title
            description
          }
        }
      }
    }
    investorPostStatic: markdownRemark(
      frontmatter: { en: { templateKey: { eq: "general-investor-post" } } }
    ) {
      id
      frontmatter {
        en {
          images {
            banner {
              imageSet {
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                imageMobile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            footer {
              imageSet {
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                imageMobile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          about {
            title
            body
            body2
          }
        }
        zh {
          images {
            banner {
              imageSet {
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                imageMobile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            footer {
              imageSet {
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                imageMobile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          about {
            title
            body
            body2
          }
        }
      }
    }
  }
`
