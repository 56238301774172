import React from 'react'
import ParallaxWrapBanner from 'components/ParallaxWrapBanner'
import ImageSet from 'components/ImageSet'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, layoutProps, theme, isImageDarker = false }) => {
  const { pretitle, title, imageSet } = content
  const themeClass = theme === 'dark' ? styles.themeDark : ''
  const imageDarkerClass = isImageDarker ? styles.isDarker : ''
  return (
    <div className={`block ${styles.component} ${themeClass}`}>
      <Inview className={`abs-fill fade-in up ${styles.text}`}>
        <div className='container'>
          <div className={`grid-24 ${styles.grid}`}>
            <div className={styles.textInner}>
              <div>
                {pretitle && <h5>{pretitle}</h5>}
                {title && <h1>{title}</h1>}
              </div>
            </div>
          </div>
        </div>
      </Inview>
      <Inview
        className={`abs-fill fade-in ${styles.image} ${imageDarkerClass}`}
      >
        <ParallaxWrapBanner>
          <ImageSet content={imageSet} />
        </ParallaxWrapBanner>
      </Inview>
    </div>
  )
}

export default Block
