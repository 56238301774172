import React from 'react'
import MarkdownWrap from 'components/MarkdownWrap'
import ParallaxWrap from 'components/ParallaxWrap'
import ImageSet from 'components/ImageSet'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { title, body, body2, imageSet } = content
  return (
    <div className={`block ${styles.component}`}>
      <Inview className={`fade-in ${styles.image}`}>
        <ParallaxWrap>
          <ImageSet content={imageSet} />
        </ParallaxWrap>
      </Inview>
      <Inview className={`fade-in up pad-v-standard`}>
        <div className='container'>
          <div className='grid-24'>
            {title && <h3 className={styles.title}>{title}</h3>}
          </div>
          <div className={`grid-24 ${styles.bodyWrap}`}>
            {body && (
              <div className={styles.body}>
                <MarkdownWrap body={body} />
              </div>
            )}
            {body2 && (
              <div className={styles.body2}>
                <MarkdownWrap body={body2} />
              </div>
            )}
          </div>
        </div>
      </Inview>
    </div>
  )
}

export default Block
