import React from 'react'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, locale }) => {
  const { pretitle, title, body } = content
  const buttonText = {
    en: 'Back to List',
    zh: '返回列表',
  }
  return (
    <Inview className='block pad-v-standard fade-in up'>
      <div className='container'>
        <div className='grid-24'>
          <div className={styles.heading}>
            <h5 dangerouslySetInnerHTML={{ __html: pretitle }} />
            <h2>{title}</h2>
          </div>
        </div>
        <div className='grid-24'>
          <div className={styles.post}>
            <MarkdownWrap body={body} />
            <div className={styles.buttonWrap}>
              <ButtonLink
                content={{ to: '/investors', text: buttonText[locale] }}
              />
            </div>
          </div>
        </div>
      </div>
    </Inview>
  )
}

export default Block
