import React from 'react'
import BannerImageText from 'blocks/BannerImageText/template'
import PostTextOnly from 'blocks/PostTextOnly/template'
import ImageFullTextColumns from 'blocks/ImageFullTextColumns/template'
import Footer from 'components/Footer'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import { formatDate } from 'js/utils'

const ContentType = ({ content, investorStatic, locale, layoutProps }) => {
  const { date, location, title, body } = content
  if (!locale) locale = 'en'
  const dateFormatted = formatDate(date, locale)

  const strings = {
    en: {
      investor: 'Investor Relations',
    },
    zh: {
      investor: '投资者关系',
    },
  }

  const pageContent = {
    bannerImageText: {
      title: strings[locale].investor,
      imageSet: investorStatic.images.banner.imageSet,
    },
    postTextOnly: {
      pretitle: `${dateFormatted} &nbsp;|&nbsp; ${location}`,
      title,
      body,
    },
    imageFullTextColumns: {
      imageSet: investorStatic.images.footer.imageSet,
      title: investorStatic.about.title,
      body: investorStatic.about.body,
      body2: investorStatic.about.body2,
    },
  }

  return (
    <div className='page'>
      <div className={`block theme bg-light`}>
        <HeaderVersionWrap layoutProps={layoutProps} isDark={false}>
          <BannerImageText
            content={pageContent.bannerImageText}
            layoutProps={layoutProps}
          />
          <PostTextOnly content={pageContent.postTextOnly} locale={locale} />
        </HeaderVersionWrap>
      </div>
      <div className={`block theme bg-dark`}>
        <HeaderVersionWrap layoutProps={layoutProps} isDark={true}>
          <ImageFullTextColumns content={pageContent.imageFullTextColumns} />
        </HeaderVersionWrap>
      </div>
      <Footer locale={locale} />
    </div>
  )
}

export default ContentType
