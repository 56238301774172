import React from 'react'
import ImageWrap from 'components/ImageWrap'
import * as styles from './styles.module.scss'

const Component = ({ content }) => {
  const { image, imageMobile } = content || {}
  const setImageMobile = imageMobile ? imageMobile : image

  return (
    <>
      <div className={styles.image}>
        <ImageWrap image={image} />
      </div>
      <div className={styles.imageMobile}>
        <ImageWrap image={setImageMobile} />
      </div>
    </>
  )
}
export default Component
